
import { mapGetters } from 'vuex'
export default {
    computed: {
        ...mapGetters({
            desktopBackground: 'cms/desktopBackground',
            mobileBackground: 'cms/mobileBackground',
            rightFloatingSupportIcon: 'cms/rightFloatingSupportIcon',
            rightFloatingMessageIcon: 'cms/rightFloatingMessageIcon',
            getSequenceAbleComponent: 'cms/getSequenceAbleComponent',
        }),
        sequenceComponentCmsForDesktop() {
            return [
                {
                    name: 'Theme2DesktopHomeSlider',
                    code: 'SliderContentDesktop',
                },
                {
                    name: 'Theme2DesktopVideoContent1And2',
                    code: 'VideoContent1',
                },
                {
                    name: 'Theme2DesktopFeaturedContent1',
                    code: 'FeaturedContent1',
                },
                {
                    name: 'Theme2DesktopJackpotContent',
                    code: 'JackpotContent',
                },
                {
                    name: 'Theme2DesktopPromotionContent',
                    code: 'PromotionContent',
                },
                {
                    name: 'Theme2DesktopFeaturedContent2',
                    code: 'FeaturedContent2',
                },
                {
                    name: 'Theme2DesktopRecentWinners',
                    code: 'RecentWinners',
                },
                {
                    name: 'Theme2DesktopTopWinnerUnluckyWinner',
                    code: 'TopWinners',
                },
                {
                    name: 'Theme2DesktopFeaturedContent3',
                    code: 'FeaturedContent3',
                },
            ]
        },
        dynamicSequenceComponentForDesktop() {
            const findMatchComponent = (componentCode) => {
                return this.getSequenceAbleComponent('desktop')?.find(
                    (el) => el.component_code === componentCode
                )?.sequence_desktop
            }
            return this.sequenceComponentCmsForDesktop
                .map((el) => {
                    return {
                        ...el,
                        sequence: findMatchComponent(el.code),
                    }
                })
                .sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence))
        },
        sequenceComponentCmsForMobile() {
            return [
                {
                    name: 'Theme2MobileHomeSlider',
                    code: 'SliderContentMobile',
                    model: false,
                },
                {
                    name: 'Theme2MobileVideoContent1And2',
                    code: 'VideoContent1',
                    model: false,
                },
                {
                    name: 'Theme2MobileFeaturedContent1',
                    code: 'FeaturedContent1',
                    model: false,
                },
                {
                    name: 'Theme2MobileJackpotContent',
                    code: 'JackpotContent',
                    model: false,
                },
                {
                    name: 'Theme2MobilePromotionContent',
                    code: 'PromotionContent',
                    model: false,
                },
                {
                    name: 'Theme2MobileFeaturedContent2',
                    code: 'FeaturedContent2',
                    model: false,
                },
                {
                    name: 'Theme2MobileFeaturedContent3',
                    code: 'FeaturedContent3',
                    model: false,
                },
            ]
        },
        dynamicSequenceComponentForMobile() {
            const findMatchComponent = (componentCode) => {
                return this.getSequenceAbleComponent('mobile')?.find(
                    (el) => el.component_code === componentCode
                )?.sequence_mobile
            }
            return this.sequenceComponentCmsForMobile
                .map((el) => {
                    return {
                        ...el,
                        sequence: findMatchComponent(el.code),
                    }
                })
                .sort((a, b) => parseFloat(a.sequence) - parseFloat(b.sequence))
        },
    },
    mounted() {
        try {
            const session = window.sessionStorage
            const toggleFloating = session.getItem('toggleFloating') // Get toggle session that set from forgot password condition contact customer support
            if (toggleFloating) {
                if (!this.isMain && this.rightFloatingMessageIcon.is_visible) {
                    this.$nuxt.$emit('@toggleAgent', true) // Toggle for agent
                } else if (
                    this.isMain &&
                    this.rightFloatingSupportIcon.is_visible
                ) {
                    this.$nuxt.$emit('@toggleSupport', true) // Toggle support button if not hide from back office
                } else if (
                    this.isMain &&
                    this.rightFloatingMessageIcon.is_visible
                ) {
                    this.$nuxt.$emit('@toggleMessage', true) // Toggle message button if support button is hiden and message button still available
                }
            }
            session.removeItem('toggleFloating') // After check remove away, no need to kip it anymore
        } catch (error) {}
    },
}
